import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../shared/services/base.api.service';
import { PagedApiResponse } from '../../../shared/models/paged-api-response.type';
import { ModuleMapper } from '../mappers/module.mapper';
import { MODULE_ENDPOINTS } from '../role-endpoints';
import { Module } from '../models/module.type';

@Injectable()
export class ModuleService extends BaseApiService<Module>  {
  model = Module;
  mapper = new ModuleMapper();
  apiEndPoint: any = MODULE_ENDPOINTS;

  getListByCompanyId(companyId: Number): Observable<PagedApiResponse<Module>> {
    return this.http.get(this.apiEndPoint.list.replace('{id}', companyId.toString())).pipe(map(res => {
      return this.sharedMapper.mapPagedApiResponse(res, this.mapper.mapList, this.model);
    }));
  }
}
