import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { SHIPPING_BILLING_ENDPOINTS } from '../billings.endpoints'; 
import { ShippingBillingMapper } from '../mappers/shipping-billing.mapper';
import { ShippingBilling } from '../models/shipping-billing';
import { FilterType } from 'app/shared/models/filter.type';
import { PagedApiResponse } from 'app/shared/models/paged-api-response.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseMapper } from 'app/shared/mappers/base.mapper';

@Injectable()
export class ShippingBillingService extends BaseApiService<ShippingBilling>  {
  model = ShippingBilling;
  mapper = new ShippingBillingMapper();
  apiEndPoint: any = SHIPPING_BILLING_ENDPOINTS;
  
  getBilling(reference: string): Observable<ShippingBilling> {
  
    return this.http.get(this.apiEndPoint.item.replace('{id}', reference))
        .pipe(map(res => {
          
            return  BaseMapper.map(res, this.model);
        }));
}

generatePDF(reference: string): Observable<any> {
  return this.http.get(this.apiEndPoint.pdf.replace('{id}', reference), {responseType: 'blob'});
}

generateEmail(reference: string): Observable<any> {
  return this.http.get(this.apiEndPoint.email.replace('{id}', reference))
      .pipe(map(res => {
        return res;
    }));
}
}
