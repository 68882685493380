import { environment as env } from '../../../../environments/environment';

const BASE_URL_NOTIFICATIONS = `${env.UpLink_ApiUrl}api/devicesnotifications`;
//const BASE_URL_NOTIFICATIONS = `http://localhost:3001/api/devices_notification`;

const BASE_URL_PRODUCTS = `${env.UpLink_ApiUrl}api/products/console/product`;

const BASE_URL_LOCATIONS = `${env.UpLink_ApiUrl}api/geolocation/console/shop`;

const BASE_URL_CUSTOMER = `${env.UpLink_ApiUrl}api/profilemanager/console/profile`;

const BASE_URL_FILEMGR = `${env.UpLink_ApiUrl}api/filemanager/file`;

export const NOTIFICATIONS_ENDPOINTS = {
  notificationTypes: `${BASE_URL_NOTIFICATIONS}/notification_types`,
  soundList: `${BASE_URL_NOTIFICATIONS}/sound_list`
};

export const PRODUCT_ENDPOINTS = {
  list: `${BASE_URL_PRODUCTS}/list`
};

export const PROVINCE_ENDPOINT = {
  provinceList: `${BASE_URL_LOCATIONS}/provinces_list`
}

export const CITIES_ENDPOINT = {
  citiesList: `${BASE_URL_LOCATIONS}/cities_list`
}

export const BRANCHOFFICE_ENDPOINT = {
  branchOfficeList: `${BASE_URL_LOCATIONS}/shops_list`
}

//api/console/shop/addresses_list
export const ADDRESSES_ENDPOINT = {
  addressList: `${BASE_URL_LOCATIONS}/addresses_list`
}

export const CUSTOMER_ENDPOINT = {
  customerList: `${BASE_URL_CUSTOMER}/customer_address`
}

export const CUSTOM_NOTIFICATION_ENDPOINT = {
  saveCustomNotification: `${BASE_URL_NOTIFICATIONS}/create_custom`,
  listCustomNotification: `${BASE_URL_NOTIFICATIONS}/custom_list`,
  detailCustomNotification: `${BASE_URL_NOTIFICATIONS}/{id}/custom_notification`,
  setStatus: `${BASE_URL_NOTIFICATIONS}/cancel_notification`
}

export const FILE_MGR_ENDPOINTS = {
  uploadImage: `${BASE_URL_FILEMGR}/upload`,
  deleteImage: `${BASE_URL_FILEMGR}/delete`,
}