import { ModelBase } from '../../../../shared/models/model.base';

export class NotificationType extends ModelBase {
  id: number = null;
  description: string = "" ;

  getNotificationType() {
    return `${this.description}`;
  }

}
