import { environment as env } from '../../../../environments/environment';

const BASE_API_URL = `${env.UpLink_ApiUrl}api`;

export const COMPANY_ENDPOINTS = {
    base: BASE_API_URL,
    list: `${BASE_API_URL}/security/company`,
    item: `${BASE_API_URL}/security/company/{id}`,
    itemList: `${BASE_API_URL}/security/company/{id}/list`,
    parentCompany: `${BASE_API_URL}/security/company/{id}/parent`,
};
