import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../../shared/services/base.api.service';
import { FilterType } from '../../../../shared/models/filter.type';
import { PagedApiResponse } from '../../../../shared/models/paged-api-response.type';
import { Bank } from '../models/bank.type';
import { BankMapper } from '../mappers/bank.mapper';
import { BANKS_ENDPOINTS } from '../banks-endpoints';

@Injectable()
export class BankService extends BaseApiService<Bank> {
    model = Bank;
    mapper = new BankMapper();
    apiEndPoint: any = BANKS_ENDPOINTS;

    create(data: Bank): Observable<boolean> {
        return this.http
            .post<boolean>(this.apiEndPoint.create, data)
            .pipe(map((res) => res));
    }

    update(data: Bank): Observable<boolean> {
        return this.http.post<boolean>(
            this.apiEndPoint.item.replace('{id}', data.id.toString()),
            data
        );
    }

    getList(): Observable<PagedApiResponse<Bank>> {
        return this.http.get(this.apiEndPoint.list).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
}
