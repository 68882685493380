
import { BaseDataSource } from '../../../../shared/datasources/base.datasource';
import { Injectable } from '@angular/core';
import { Event } from '../models/event.type';
import { EventService } from '../services/event.service';

@Injectable()
export class EventDatasource extends BaseDataSource<Event> {
  constructor(private service: EventService) {
    super(service);
  }
}
