import { Routes } from '@angular/router';
import { DefaultReportGuard } from '../../shared/guards/default-report.guard';
export const ROUTES: Routes = [
    {
        path: 'default', canActivate: [DefaultReportGuard] 
    },

    {
        path: 'dashboard',
        loadChildren: './dashboard/reports-dashboard.module#ReportsDashboard',
        canActivate: [DefaultReportGuard] 
    }
    , {
        path: 'sales',
        loadChildren: './sales/sales.module#SalesModule'
    },
    {
        path: 'marketing',
        loadChildren: './marketing/marketing.module#MarketingModule'
    } ,
    {
        path: 'thirdparty',
        loadChildren: './thirdparty/thirdparty.module#ThirdpartyModule'
    },
    {
        path: 'cobra',
        loadChildren: './cobra/cobra.module#CobraModule'
    }
];



