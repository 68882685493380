import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../../shared/services/base.api.service';
import { FilterType } from '../../../../shared/models/filter.type';
import { PagedApiResponse } from '../../../../shared/models/paged-api-response.type';
import { Term } from '../models/term.type';
import { TermMapper } from '../mappers/term.mapper';
import { TERMS_ENDPOINTS } from '../term-endpoints';

@Injectable()
export class TermService extends BaseApiService<Term> {
    model = Term;
    mapper = new TermMapper();
    apiEndPoint: any = TERMS_ENDPOINTS;

    create(data: Term): Observable<boolean> {
        return this.http
            .post<boolean>(this.apiEndPoint.create, data)
            .pipe(map((res) => res));
    }

    update(data: Term): Observable<boolean> {
        return this.http.post<boolean>(
            this.apiEndPoint.item.replace('{id}', data.id.toString()),
            data
        );
    }

    getList(): Observable<PagedApiResponse<Term>> {
        return this.http.get(this.apiEndPoint.list).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
}
