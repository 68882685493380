import { ModelBase } from '../../../../shared/models/model.base';

export class Bank extends ModelBase {
    id: number;
    codBank: string;
    nameBank: string;
    status: number;

    constructor() {
        super();
        this.codBank = '';
        this.nameBank = '';
    }
}
