import { ModelBase } from "../../../../shared/models/model.base";

export class ShippingBilling extends ModelBase {
    factura: ShippingBillingFactura;
    status: string;
    constructor() {
        super();
    }
}

export class ShippingBillingFactura {
  products: Array<ShippingBillingProducts>;
  details:  ShippingBillingDetails;
}

class ShippingBillingProducts {
    cant: string;
    description: string;
    value: string;
    valueNoIva: string;
    discount: string;
    total: string;
}

class ShippingBillingDetails {
  paidDescription: string;
  total: number;
  extra: Array<ShippingBillingExtra>;
  payment_brand: string;
  last_four_digits: string;
  payment_brand_detail: PaymentBrandDetail;
}

class PaymentBrandDetail {
    id: string;
    url: string;
    color: string;
}

class ShippingBillingExtra {
  name: string;
  value: string;
}
