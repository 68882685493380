import { environment as env } from '../../../../environments/environment';

const BASE_URL = `${env.UpLink_ApiUrl}api/shipping`;

export const DASHBOARD_ENDPOINTS = {
    orders_by_statuses: `${BASE_URL}/stats/grp/statuses/webAdmin`,
    statuses: `${BASE_URL}/statuses/{companyId}`,
    orders_by_status: `${BASE_URL}/stats/grp/status`,
    orders_feedbacks: `${BASE_URL}/stats/grp/feedbacks`,
    saleOrder: `${env.UpLink_ApiUrl}api/saleorderdiscount/saleorderDisc/{id}`,
};
