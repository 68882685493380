import { environment as env } from "../../../../environments/environment";

const BASE_EMAILING = `${env.UpLink_ApiUrl}api/emailing`;

export const TEMPLATE_ENDPOINTS = {
    item: `${BASE_EMAILING}/template/{id}`,  
    list: `${BASE_EMAILING}/template`,
};


