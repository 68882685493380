import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { SoundCatalog } from '../models/sound.type';
import { NOTIFICATIONS_ENDPOINTS } from 'app/features/appnotifications/custom/custom-appnotification.endpoints';
import { SoundCatalogMapper } from './../mappers/notificationType.mapper';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class SoundService extends BaseApiService<SoundCatalog>  {
  model = SoundCatalog;
  mapper = new SoundCatalogMapper();
  apiEndPoint: any = NOTIFICATIONS_ENDPOINTS;

  getComboSoundList(): Observable<Array<SoundCatalog>> {
    return this.http.get(this.apiEndPoint.soundList)
    .pipe(
      map(res => {
        return this.mapper.mapList(res, this.model);
    }));
  }

}
