import { BaseDataSource } from '../../../../shared/datasources/base.datasource';
import { Injectable } from '@angular/core';
import { Product } from '../models/product.type';
import { ProductService } from '../services/products.service';

@Injectable()
export class ProductsDatasource extends BaseDataSource<Product> {
    originalRows: Product[];

    constructor(private service: ProductService) {
        super(service);
        this.service = service;
    }

    getList() {
        const self = this;

        if (!self.service) {
            return;
        }
        self.loading = true;

        self.service.getList(self.filter).subscribe((res) => {
            self.collectionList.next(res.rows);
            self.originalRows = res.rows;
            self.rows = res.rows;
            self.count = res.count;
            self.loading = false;
        });
    }

    getListFind() {
        const self = this;

        if (!self.service) {
            return;
        }
        self.loading = true;

        let rate = self.filter.filter.find((x) => x.attrName == 'rateOption');
        let filterText = self.filter.filter.find((x) => x.attrName == 'name');

        var info = {
            companyId: self.filter.companyId,
            limit: self.filter.limit,
            offset: self.filter.offset,
            name: filterText ? filterText.attrValue : null,
            categoryList: self.filter.categoryArray,
            rateOption: rate ? rate.attrValue : null,
        };

        self.service.getListFilter(info).subscribe((res) => {
            self.collectionList.next(res.rows);
            self.originalRows = res.rows;
            self.rows = res.rows;
            self.count = res.count;
            self.loading = false;
        });
    }
}
