import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { TemplateMapper } from '../mappers/template.mapper';
import { Template } from '../models/template.type';
import { TEMPLATE_ENDPOINTS } from '../template.endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { SharedMapper } from 'app/shared/mappers/shared.mapper';

import { Response } from 'app/shared/models/response';
@Injectable({
    providedIn: 'root',
})
export class TemplateService extends BaseApiService<Template> {
    model = Template;
    mapper = new TemplateMapper();
    apiEndPoint: any = TEMPLATE_ENDPOINTS;

    constructor(
        http: HttpClient,
        public sharedMapper: SharedMapper,
        handler: HttpBackend
    ) {
        super(http, sharedMapper);
    }

    update(data: any): Observable<Response> {
        return this.http.put<Response>(
            this.apiEndPoint.item.replace('{id}', data.id),
            data
        );
    }

    create(data: any): Observable<Response> {
        return this.http.put<Response>(this.apiEndPoint.list, data).pipe(
            map((res) => {
                return res;
            })
        );
    }
}
