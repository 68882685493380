import { ModelBase } from '../../../../shared/models/model.base';

export class Term extends ModelBase {
    id: number;
    umID: number;
    term: string;
    description: string;
    status: number;

    constructor() {
        super();
        this.umID = 0;
        this.term = '';
        this.description = '';
    }
}
