import { environment as env } from '../../../../environments/environment';

const BASE_URL_BILLINGS =  `${env.UpLink_ApiUrl}api/billing`;

export const BILLING_ENDPOINTS = {
  base: `${BASE_URL_BILLINGS}`,
  list: `${BASE_URL_BILLINGS}/list`,
  listFind: `${BASE_URL_BILLINGS}/find`,
  item: `${BASE_URL_BILLINGS}/{id}`,
};

export const BILLING_SUBSCRIPTION_ENDPOINTS = {
  list: `${BASE_URL_BILLINGS}/{id}/list`,
  listFind: `${BASE_URL_BILLINGS}/{id}/list`
};

export const CUSTOMER_ENDPOINTS = {
  item: `${env.UpLink_ApiUrl}api/profilemanager/company/customers/{id}`
};

export const SHIPPING_BILLING_ENDPOINTS = {
  item: `${BASE_URL_BILLINGS}/{id}/invoice`,
  email: `${BASE_URL_BILLINGS}/{id}/invoice/email`,
  pdf: `${BASE_URL_BILLINGS}/{id}/invoice/PDF`,
};



