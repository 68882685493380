import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseDataSource } from '../../../../shared/datasources/base.datasource';
import { BankService } from '../services/bank.service';
import { Bank } from '../models/bank.type';

@Injectable()
export class BankDatasource extends BaseDataSource<Bank> {
    originalRows: Bank[];
    constructor(private service: BankService) {
        super(service);
    }

    get(id: number): Observable<Bank> {
        return this.service.get(id);
    }

    create(data: Bank): Observable<any> {
        return this.service.create(data);
    }

    update(data: Bank): Observable<boolean> {
        return this.service.update(data);
    }

    getList() {
        const self = this;

        if (!self.service) {
            return;
        }

        self.loading = true;

        self.service.getList().subscribe((res) => {
            self.originalRows = res.rows;
            self.rows = res.rows;
            self.count = res.count;
            self.loading = false;
        });
    }
}
