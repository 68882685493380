import { ModelForm } from '../../../../shared/models/model.form';

export class List extends ModelForm {
  companyId: string ;
  name = "";
  status: boolean ;
  generated = false;

  constructor() {
    super();
  }

  getTitle() {
    return `${this.name}`;
}
}
