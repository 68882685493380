import { environment as env } from '../../../environments/environment';

// TODO: Change UpLink_ApiUrl by UpLink_ApiUrl when merge v2 branch into develop/master
const BASE_API_URL = `${env.UpLink_ApiUrl}api`;

export const ROLE_ENDPOINTS = {
  base: BASE_API_URL,
  list: `${BASE_API_URL}/security/roles`,
  item: `${BASE_API_URL}/security/roles/{id}`,
  listByCompany: `${BASE_API_URL}/security/roles/company/{id}`,
};

export const MODULE_ENDPOINTS = {
  base: BASE_API_URL,
  list: `${BASE_API_URL}/security/modules/company/{id}`,
  item: `${BASE_API_URL}/security/modules/{id}`,
};
