import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as toastr from 'toastr';
import {
    MatIconModule, MatButtonModule,
    MatCheckboxModule, MatSelectModule, MatSlideToggleModule, MatTableModule, MatFormFieldModule, MatMenuModule
} from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreSharedModule } from '../../core/coreshared.module';
import { DashmeterComponent } from './components/dashmeter/dashmeter.component';
import { ROUTES } from './reporting.routes';

import { GLinkNavigationService } from 'app/core/components/navigation/navigation.service';
import { GLinkNavigation } from 'app/core/types';
import { MarketingComponent } from './marketing/marketing.component';
import { ObservableDataService } from '../../core/services/observable-data.service';
@NgModule({
    declarations: [
        DashmeterComponent
    ],
    imports: [

        MatMenuModule,
        RouterModule.forChild(ROUTES),
        NgxDatatableModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        CoreSharedModule,
        TranslateModule
    ],
    exports: [
        DashmeterComponent
    ]

})

export class ReportingModule {

    static redirected = false;

    nav: GLinkNavigation[] = [];
    constructor(
        private _glinkNavigationService: GLinkNavigationService,
        private ods: ObservableDataService,
        private router: Router) {
        
        const sessionInfo = this.ods.getLoggedUser();
        // Buscar la lista única de reportes, ya que la información viene en formato maestro-detalle.
        const resultUniqueItems = [];
        const map = new Map();
        for (const item of sessionInfo.reportsByUser) {
            if ( !map.has(item.codeReport) ) {
                map.set(item.codeReport, true);    // set any value to Map
                resultUniqueItems.push( {
                    id: item.codeReport,
                    title: item.titleReport,
                    icon: item.iconReport,
                    type: item.typeReport,
                    url: item.urlReport ? item.urlReport : toastr.warning(`Modulo sin datos.`)
                });
            }
        }
        this.nav.push(...resultUniqueItems);

        // Register the navigation to the service
        this._glinkNavigationService.register('reports', this.nav);

        // Set the main navigation as our current navigation
        this._glinkNavigationService.setCurrentNavigation('reports');

        // para que se carge el primer reporte 
        //if (!ReportingModule.redirected) {
        //router.navigate([sessionInfo.reportsByUser[0].urlReport]);
        //ReportingModule.redirected = true;
       // }
    
    }

    
}
