import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../shared/services/base.api.service';
import { PagedApiResponse } from '../../../shared/models/paged-api-response.type';
import { COMPANY_ENDPOINTS } from '../company/company-endpoints';
import { CompanyMapper } from '../company/company.mapper';
import { Company } from '../company/company.type';

@Injectable()
export class CompanyService extends BaseApiService<Company> {
    model = Company;
    mapper = new CompanyMapper();
    apiEndPoint: any = COMPANY_ENDPOINTS;

    save(data: any): Observable<any> {
        return this.http
            .put(this.apiEndPoint.save, data)
            .pipe(map((res) => res));
    }

    getList(): Observable<PagedApiResponse<Company>> {
        return this.http.get(this.apiEndPoint.list).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }

    getListByCompanyId(
        companyId?: number
    ): Observable<PagedApiResponse<Company>> {
        return this.http
            .get(this.apiEndPoint.itemList.replace('{id}', companyId))
            .pipe(
                map((res) => {
                    return this.sharedMapper.mapPagedApiResponse(
                        res,
                        this.mapper.mapList,
                        this.model
                    );
                })
            );
    }

    getParentCompany(companyId?: number): Observable<Company> {
        const endpoint = this.apiEndPoint.parentCompany.replace(
            '{companyId}',
            companyId.toString()
        );
        return this.http.get<Company>(endpoint);
    }

    create(data: Company): Observable<boolean> {
        return this.http
            .post<boolean>(this.apiEndPoint.list, data)
            .pipe(map((res) => res));
    }

    update(data: Company): Observable<boolean> {
        return this.http.put<boolean>(
            this.apiEndPoint.item.replace('{id}', data.id.toString()),
            data
        );
    }
}
