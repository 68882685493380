import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../shared/services/base.api.service';
import { PagedApiResponse } from '../../../shared/models/paged-api-response.type';
import { ROLE_ENDPOINTS } from '../role-endpoints';
import { RoleMapper } from '../mappers/role.mapper';
import { Role } from '../models/role.type';

@Injectable()
export class RoleService extends BaseApiService<Role>  {
  model = Role;
  mapper = new RoleMapper();
  apiEndPoint: any = ROLE_ENDPOINTS;

  create(data: Role): Observable<boolean> {
    return this.http.post<boolean>(this.apiEndPoint.list, data)
      .pipe(map(res => res));
  }

  getList(): Observable<PagedApiResponse<Role>> {
    return this.http.get(this.apiEndPoint.list).pipe(map(res => {
      return this.sharedMapper.mapPagedApiResponse(res, this.mapper.mapList, this.model);
    }));
  }

  getListByCompany(companyId: number): Observable<PagedApiResponse<Role>> {
    return this.http.get(this.apiEndPoint.listByCompany.replace('{id}', companyId.toString())).pipe(map(res => {
      return this.sharedMapper.mapPagedApiResponse(res, this.mapper.mapList, this.model);
    }));
  }

  update(data: Role): Observable<boolean> {
    const { id } = data;
    const endpoint = this.apiEndPoint.item.replace('{id}', id.toString());
    return this.http.put<boolean>(endpoint, data);
  }

  delete(id: number): Observable<boolean> {
    const endpoint = this.apiEndPoint.item.replace('{id}', id.toString());
    return this.http.delete<boolean>(endpoint);
  }
}
