import { Injectable } from "@angular/core";

import { BaseApiService } from "../../../../shared/services/base.api.service";
import { EVENT_ENDPOINTS } from "../events.endpoints";
import { EventMapper } from "../mappers/event.mapper";
import { map } from "rxjs/operators";
import { FilterType } from "app/shared/models/filter.type";
import { Observable } from "rxjs";
import { PagedApiResponse } from "app/shared/models/paged-api-response.type";
import { Event } from "../models/event.type";

@Injectable()
export class EventService extends BaseApiService<Event> {
    model = Event;
    mapper = new EventMapper();
    apiEndPoint: any = EVENT_ENDPOINTS;
}
