import { environment as env } from "../../../../environments/environment";

const BASE_EMAILING = `${env.UpLink_ApiUrl}api/emailing`;

export const LIST_ENDPOINTS = {
    list: `${BASE_EMAILING}/list`,
    item: `${BASE_EMAILING}/list/{id}`,  
    uploadFile: `${BASE_EMAILING}/upload/{id}`   
};


