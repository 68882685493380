import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { NotificationType } from '../models/notificationType.type';
import { NOTIFICATIONS_ENDPOINTS } from '../custom-appnotification.endpoints';
import { NotificationTypeMapper } from '../mappers/notificationType.mapper';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationTypeService extends BaseApiService<NotificationType>  {
  model = NotificationType;
  mapper = new NotificationTypeMapper();
  apiEndPoint: any = NOTIFICATIONS_ENDPOINTS;

  getComboNotificationTypesList(): Observable<Array<NotificationType>> {
    return this.http.get(this.apiEndPoint.notificationTypes)
    .pipe(
      map(res => {
        return this.mapper.mapList(res, this.model);
    }));
  }

}
