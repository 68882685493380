import { ModelBase } from "../../../../shared/models/model.base";

export class Event extends ModelBase {
    companyId: number = null;
    id: number = null;
    name: string = null;
    shortDescription: string = null;
    avatarUrl: string = null;
    coverUrl: string = null;
    urlKey: string = null;
    goal: number = null;
    startDate: Date = null;
    endDate: Date = null;
    isPublic: boolean = null;
    isActive: boolean = null;
    isDeleted: boolean = null;
    renewalPeriodType: number = null;
    
    constructor() {
        super();
    }

    getTitle() {
        return `${this.name}`;
    }
}
