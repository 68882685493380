import { ModelForm } from '../../../../shared/models/model.form';

export class Template extends ModelForm {
  companyId: string ;
  name = "";
  html = "";
  subject = "";
  status: boolean ;

  generated = false;

  constructor() {
    super();
  }

  getTitle() {
    return `${this.name}`;
}
}
