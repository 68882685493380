import { Component, OnInit, Input } from '@angular/core'; 
import { glinkAnimations } from 'app/core/animations'; 
import { DashmeterMenu, DashmeterData} from '../../models/dashmeter.type'; 
@Component({
  selector: 'dashmeter',
  templateUrl: './dashmeter.component.html',
  styleUrls: ['./dashmeter.component.scss'],
  animations: glinkAnimations
})
export class DashmeterComponent implements OnInit  {
    @Input() dashmeterData: DashmeterData[];
    @Input() dashmeterMenu: DashmeterMenu[];
    dashmeterSeleccionado: number;
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
        
          this.dashmeterSeleccionado = this.dashmeterMenu[0].index;
          
  }
 
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

}
