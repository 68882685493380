
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataSource } from '../../../shared/datasources/base.datasource';
import { RoleService } from '../services/role.service';
import { Role } from '../models/role.type';


@Injectable()
export class RoleDatasource extends BaseDataSource<Role> {
  constructor(private service: RoleService) {
    super(service);
  }

  get(id: number): Observable<Role> {
    return this.service.get(id);
  }

  create(data: Role): Observable<boolean> {
    return this.service.create(data);
  }


  delete(id: number): Observable<boolean> {
    return this.service.delete(id);
  }

  update(data: Role): Observable<boolean> {
    return this.service.update(data);
  }

  getList() {
    const self = this;

    if (!self.service) {
      return;
    }

    self.loading = true;

    self.service.getList().subscribe(res => {
      self.rows = res.rows;
      self.count = res.count;
      self.loading = false;
    });
  }

  getListInside(companyId: number) {
    const self = this;

    if (!self.service) {
      return;
    }

    self.loading = true;

    return self.service.getListByCompany(companyId);
  }
}
