import { ModelBase } from '../../../shared/models/model.base';

export class Module extends ModelBase {
  name: string;
  displayName: string;
  description: string;
  active: boolean;

  subModules: any[];
  moduleActions: any[];
  actions: any[];

  constructor() {
    super();
  }
}
