import { GLinkNavigation } from 'app/core/types';

export const navigation: GLinkNavigation[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/customersSaleOrder'
  },
  {
    id: 'list',
    title: 'Clientes',
    type: 'item',
    icon: 'account_circle',
    url: '/customersSaleOrder/list'
  },
  {
    id: 'viewReports',
    title: 'Reportes',
    type: 'group',
    icon: 'assessment',
    children:
      [{
        id: 'exportExcel',
        title: 'Reporte de Órdenes de clientes',
        type: 'item',
        url: '/customersSaleOrder/reports/customerSaleOrdersToExcel'
      }]
  }
];
