import { ModelBase } from '../../../shared/models/model.base';

export class Role extends ModelBase {
  id: number;
  name: string;
  active: boolean;
  isShared: boolean;

  // for post
  userId: number;
  roleId: number;

  subModules: any[];
  roleActions: any[];
  roleCompanies: any[];

  constructor() {
    super();
    this.active = true;
  }
}
