import { GLinkNavigation } from 'app/core/types';

export const navigation: GLinkNavigation[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/shippings/'
  },
  {
    id: 'list',
    title: 'Todas',
    type: 'item',
    icon: 'grid_on',
    url: '/shippings/list/all'
  },
  {
    id: 'viewReports',
    title: 'Reportes',
    type: 'group',
    icon: 'assessment',
    children:
      [{
        id: 'exportExcel',
        title: 'Exportar órdenes a excel',
        type: 'item',
        url: '/shippings/reports/ordersToExcel'
      }]
  }  /*,

  type: 'collapsable',
    children:
      [{
        id: 'exportExcel',
        title: 'Exportar órdenes a excel',
        type: 'item',
        url: '/shippings/reports/ordersToExcel'
      }]
  {
    id: 'preparing_orders',
    title: 'En Proceso',
    type: 'item',
    icon: 'timelapse',
    url: '/shippings/list/preparing'
  },
  {
    id: 'shipped_orders',
    title: 'Enviadas',
    type: 'item',
    icon: 'local_shipping',
    url: '/shippings/list/shipped'
  },
  {
    id: 'delivered_orders',
    title: 'Entregadas',
    type: 'item',
    icon: 'check_box',
    url: '/shippings/list/delivered'
  },
  {
    id: 'all_orders',
    title: 'Todas',
    type: 'item',
    icon: 'grid_on',
    url: '/shippings/list/all'
  },
  {  
    id: 'Status',
    title: 'Status',
    icon: 'grid_on',
    type: 'collapsable',
    children:
     [{
        id: 'Hijo',
        title: 'Hijo',
        type: 'item',
        url: '/shippings/list/News/1'

    },
    {
      id: 'Hijo2',
      title: 'Hijo2',
      type: 'item',
      url: '/shippings/list/Preparing/2'

   }]*/

];
