import { ModelBase } from "../../../shared/models/model.base";


export class Company extends ModelBase {

  id: number;
  name: string;
  url: string;
  urlEcommerce: string;
  email: string;
  isActive: boolean;
  isSaaSOwner: boolean;
  parentId: number;

  constructor() {
    super();
    this.name = '';
    this.email = '';
    this.isActive = true;
    this.isSaaSOwner = false;
  }
}
