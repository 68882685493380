import { ModelBase } from '../../../../shared/models/model.base';

export class Card extends ModelBase {
    id: number;
    codCard: string;
    cardBrand: string;
    icon: string;
    status: number;

    constructor() {
        super();
        this.codCard = '';
        this.cardBrand = '';
        this.icon = '';
    }
}
