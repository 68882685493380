import { environment as env } from "../../../../environments/environment";

const BASE_EMAILING = `${env.UpLink_ApiUrl}api/reminder`;

export const TEMPLATE_ENDPOINTS = {
    item: `${BASE_EMAILING}/template/{id}`,  
    list: `${BASE_EMAILING}/template`,
    base: `${BASE_EMAILING}/template`,
    campaign: `${env.UpLink_ApiUrl}api/emailing/campaigns`
};


