import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { ListMapper } from '../mappers/list.mapper';
import { List } from '../models/list.type';
import { LIST_ENDPOINTS } from '../list.endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { SharedMapper } from 'app/shared/mappers/shared.mapper';

import { Response } from 'app/shared/models/response';
@Injectable({
    providedIn: 'root',
})
export class ListService extends BaseApiService<List> {
    model = List;
    mapper = new ListMapper();
    apiEndPoint: any = LIST_ENDPOINTS;

    protected fileClient: HttpClient;

    constructor(
        http: HttpClient,
        public sharedMapper: SharedMapper,
        handler: HttpBackend
    ) {
        super(http, sharedMapper);
        this.fileClient = new HttpClient(handler);
    }

    update(data: any): Observable<Response> {
        return this.http.put<Response>(
            this.apiEndPoint.item.replace('{id}', data.id),
            data
        );
    }

    create(data: any): Observable<Response> {
        return this.http.put<Response>(this.apiEndPoint.list, data).pipe(
            map((res) => {
                return res;
            })
        );
    }

    uploadFile(
        companyId: number,
        file: any,
        id: number,
        firstRowIndex: boolean,
        csvSeparator: string,
        csvColumn: number
    ): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                apiKey: env.apiKey,
            }),
        };
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('firstRowIndex', firstRowIndex.toString());
        formData.append('csvSeparator', csvSeparator);
        formData.append('csvColumn', csvColumn.toString());
        formData.append('companyId', companyId.toString());

        return this.fileClient
            .post(
                this.apiEndPoint.uploadFile.replace('{id}', id),
                formData,
                headers
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
}
