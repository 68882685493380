import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DASHBOARD_ENDPOINTS } from '../shipping-dashboard.endpoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class ShippingDashboardService {
  apiEndPoint: any = DASHBOARD_ENDPOINTS;

  constructor (protected http: HttpClient) {
    
  }

  countShippingByStatuses(filters?): Observable<any> {

    return this.http.post<any>(this.apiEndPoint.orders_by_statuses, filters)
    .pipe(map(response => response));
  }

  getShippingStatuses(companyId): Observable<any> {

    return this.http.get<any>(this.apiEndPoint.statuses.replace('{companyId}', companyId.toString()))
    .pipe(map(response => response));
  }


  countShippingByStatus(filters?): Observable<any> {

    return this.http.post<any>(this.apiEndPoint.orders_by_status, filters)
    .pipe(map(response => response));
  }

  countShippingFeedbacks(filters?): Observable<any> {

    return this.http.post<any>(this.apiEndPoint.orders_feedbacks, filters)
    .pipe(map(response => response));
  }

  getSaleOrder(id: number) {
    return this.http.get(this.apiEndPoint.saleOrder.replace('{id}', id.toString()))
      .pipe(map(items => {
        return items;
      }));
  }
}
